/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import SEODetails from "../block-library/components/seo-details"
import Layout from "../components/layout"

const UserSitemap = ({
  data: {
    strapi: { homePage, blogCategories, blogSubcategories, blogPosts },
  },
}) => {
  const { t } = useTranslation()
  const targetCategory = null

  return (
    <Layout title={t("TableOfContents.title")}>
      <SEODetails
        title={t("TableOfContents.title")}
        {...{ robotsFollow: true, robotsIndex: false }}
      />

      <Container
        sx={{
          variant: "layout.sectionMargin",
          mt: [8, 9],
        }}
      >
        <Heading as="h1" size="xl" mb={[5, 7]}>
          {t("TableOfContents.heading")}
        </Heading>

        {blogCategories
          .filter((blogCategory) => !blogCategory.isSeoTestPage)
          .map((blogCategory) => (
            <div
              key={blogCategory.id}
              sx={{
                position: "relative",
                pb: blogCategory.name.toLowerCase() === targetCategory ? 2 : 0,
                mb: [5, 7],
              }}
            >
              <Heading as="h2" mb={2}>
                <TextLink href={`/${blogCategory.slug}`} isTextColor>
                  {blogCategory.name}
                </TextLink>
              </Heading>

              <ul
                sx={{
                  my: 0,
                  pl: 0,
                  listStyleType: "none",
                  columns: [null, 2],
                  columnGap: [null, 7],
                }}
              >
                {blogSubcategories
                  .filter((blogSubcategory) => !blogSubcategory.isSeoTestPage)
                  .filter(
                    (blogSubcategory) =>
                      blogSubcategory.blogCategory.id === blogCategory.id
                  )
                  .map((blogSubcategory) => (
                    <li
                      key={blogSubcategory.id}
                      sx={{
                        display: "flex",
                        "&:before": {
                          content: "'-'",
                          mr: 3,
                        },
                        "&:not(:last-of-type)": {
                          mb: 2,
                        },
                      }}
                    >
                      <TextLink
                        href={`/${blogCategory.slug}/${blogSubcategory.slug}`}
                        isTextColor
                      >
                        {blogSubcategory.name}
                      </TextLink>
                    </li>
                  ))}
                {blogPosts
                  .filter((blogPost) => !blogPost.isSeoTestPage)
                  .filter(
                    (blogPost) =>
                      blogPost.blogSubcategory.blogCategory.id ===
                      blogCategory.id
                  )
                  .map((blogPost) => (
                    <li
                      key={blogPost.id}
                      sx={{
                        display: "flex",
                        "&:before": {
                          content: "'-'",
                          mr: 3,
                        },
                        "&:not(:last-of-type)": {
                          mb: 2,
                        },
                      }}
                    >
                      <TextLink
                        href={`/${blogCategory.slug}/${blogPost.blogSubcategory.slug}/${blogPost.slug}`}
                        isTextColor
                      >
                        {blogPost.title}
                      </TextLink>
                    </li>
                  ))}
              </ul>
              {blogCategory.name.toLowerCase() === targetCategory && (
                <TextLink
                  href={homePage.hero.primaryCta.targetUrl}
                  isTextColor
                  sx={{
                    position: "absolute",
                    display: "block",
                    bottom: 0,
                    transform: "translateY(100%)",
                    "&:before": {
                      content: "'-'",
                      mr: 3,
                    },
                  }}
                >
                  {homePage.hero.primaryCta.label}
                </TextLink>
              )}
            </div>
          ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      homePage {
        hero {
          primaryCta {
            targetUrl
            label
          }
        }
      }
      blogCategories {
        id
        name
        slug
        isSeoTestPage
      }
      blogSubcategories {
        id
        name
        slug
        isSeoTestPage
        blogCategory {
          id
        }
      }
      blogPosts {
        id
        title
        slug
        isSeoTestPage
        blogSubcategory {
          slug
          blogCategory {
            id
          }
        }
      }
    }
  }
`

export default UserSitemap
